// extracted by mini-css-extract-plugin
export var AddCanTxt1 = "AppointmentDetails-module--AddCanTxt1--ZzrAE";
export var CalCo1 = "AppointmentDetails-module--CalCo1--SBXXp";
export var addToCon = "AppointmentDetails-module--addToCon--7U-Gq";
export var apptmntDtls = "AppointmentDetails-module--apptmntDtls--SEXCK";
export var apptmntDtls_calendarCta = "AppointmentDetails-module--apptmntDtls_calendarCta--6zaMw";
export var apptmntDtls_calendarCta_btn = "AppointmentDetails-module--apptmntDtls_calendarCta_btn--8IgjE";
export var apptmntDtls_calendarCta_btnCntnt = "AppointmentDetails-module--apptmntDtls_calendarCta_btnCntnt---LzVS";
export var apptmntDtls_calendarCta_img = "AppointmentDetails-module--apptmntDtls_calendarCta_img--F--WM";
export var apptmntDtls_date = "AppointmentDetails-module--apptmntDtls_date--nKoKk";
export var apptmntDtls_docDtls = "AppointmentDetails-module--apptmntDtls_docDtls--tj2im";
export var apptmntDtls_docImg = "AppointmentDetails-module--apptmntDtls_docImg--OCTok";
export var apptmntDtls_docSec = "AppointmentDetails-module--apptmntDtls_docSec--Hc9Xa";
export var apptmntDtls_hdng = "AppointmentDetails-module--apptmntDtls_hdng--9JB1c";
export var apptmntDtls_mtngDtls = "AppointmentDetails-module--apptmntDtls_mtngDtls--9-eSx";
export var apptmntDtls_mtngLink = "AppointmentDetails-module--apptmntDtls_mtngLink--q2AMQ";
export var apptmntDtls_time = "AppointmentDetails-module--apptmntDtls_time--OtKco";
export var calImg1 = "AppointmentDetails-module--calImg1--4-UWx";