import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// css
import * as styles from "./Share.module.css";

const propTypes = {
  link: PropTypes.string,
};

const defaultProps = {
  link: "/twinhealth.com",
};

const Share = props => {
  const [cLink, setClink] = useState();
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setClink(window.location.origin + props.link);
    }
  }, [props.link]);

  const copyEvent = e => {
    setCopy(true);
    navigator.clipboard.writeText(cLink);
  };

  return (
    <>
      <div
        onClick={copyEvent}
        role="presentation"
        className={`d-flex ${styles.share_sec}`}
      >
        <div className={`d-flex ${styles.share_link}`}>
          <p>{props.link}</p>
        </div>
        <div className={`d-flex ${styles.share_cpyTxt}`}>
          {copy ? <p>Copied</p> : <p>Copy link</p>}
        </div>
      </div>
    </>
  );
};

Share.propTypes = propTypes;
Share.defaultProps = defaultProps;

export default Share;

// How to use
// Ex1:- <Share />
// Ex2:- <Share link="twinhealth.com/doctor-consult" />
