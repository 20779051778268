import React, { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import { StaticImage } from "gatsby-plugin-image";
import { Image, Placeholder } from "cloudinary-react";

// config
import config from "../../../config";

// Localstorage
import LS from "../../../utils/LocalStorageService";

// styles
import * as styles from "./AppointmentDetails.module.css";

const AppointmentDetails = props => {
  const [day, setDay] = useState(null);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    if (props.successData) {
      const day =
        props.successData &&
        props.successData.schedulerStartTime &&
        format(parseISO(props.successData.schedulerStartTime), "dd MMM, yyyy");
      const startTime =
        props.successData &&
        props.successData.schedulerStartTime &&
        format(parseISO(props.successData.schedulerStartTime), "hh:mm aaa");

      setDay(day);
      setStartTime(startTime);
    }
  }, [props.successData]);

  // function to add to google calendar
  const getCallbackUrl = () => {
    const headerValue = LS.getValue("header");

    if (headerValue && headerValue.appHeader === "mobile") {
      return (
        window.location.origin +
        "/doctor-consult-successful/?appHeader=mobile&orderId=" +
        props.orderId +
        "&addToC=1"
      );
    } else {
      return (
        window.location.origin +
        "/doctor-consult-successful/?orderId=" +
        props.orderId +
        "&addToC=1"
      );
    }
  };

  const addToCalender = () => {
    if (window !== "undefined") {
      const url = encodeURIComponent(getCallbackUrl());

      window.location =
        config.DX_API_BASE_URL +
        "/api/cal/add/" +
        props.orderId +
        "?url=" +
        url;
    }
  };

  return (
    <div className={styles.apptmntDtls}>
      {/* heading */}
      <div className={styles.apptmntDtls_hdng}>
        <h3>APPOINTMENT DETAILS</h3>
      </div>
      {/* Calendar details */}
      <div className="row mt-4" style={{ paddingLeft: "12px" }}>
        {/* date */}
        <div className="col-6 col-md-6 col-lg-6">
          <div className={`d-flex ${styles.apptmntDtls_date}`}>
            <span>
              <span
                className={`mediumIcon icon-Date`}
                aria-hidden="true"
                style={{ color: "#111111", fontSize: "19px" }}
              ></span>
            </span>
            <p>{day}</p>
          </div>
        </div>
        {/* time */}
        <div className="col-6 col-md-6 col-lg-6">
          <div className={`d-flex ${styles.apptmntDtls_time}`}>
            <span
              className={`mediumIcon icon-08`}
              aria-hidden="true"
              style={{ color: "#111111", fontSize: "19px" }}
            ></span>
            <p>{startTime}</p>
          </div>
        </div>
      </div>
      {/* Doctor details */}
      <div className={`row ${styles.apptmntDtls_docSec}`}>
        {/* doctor image */}
        <div className="col-5 col-md-5 col-lg-5">
          <div className={styles.apptmntDtls_docImg}>
            <img
              src={props.doctorDetails.profilePhoto}
              alt=""
              placeholder="blurred"
            />
          </div>
        </div>
        {/* doctor details */}
        {props.loading ? (
          ""
        ) : (
          <div className="col-7 col-md-7 col-lg-7">
            <div className={styles.apptmntDtls_docDtls}>
              <h3>{props.doctorDetails.name}</h3>
              <h5>{props.doctorDetails.designation}</h5>
              <h5>Speaks {props.doctorDetails.language}</h5>
              <p>
                {props.doctorDetails.experience}+{" "}
                {props.doctorDetails.experienceDescription}
              </p>
            </div>
          </div>
        )}
      </div>
      {/* Meeting Link */}
      <div className={`row ${styles.apptmntDtls_mtngDtls}`}>
        <div className="col-12">
          <h3>MEETING LINK</h3>
        </div>
        <div className="col-12">
          <div className="">
            {props.successData.meetingLink ? (
              <div className="col-12 d-flex align-items-center pt-3">
                <div className="">
                  <StaticImage
                    src="../../../images/doctor/meet.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    style={{ width: "24px", height: "24px" }}
                    width={24}
                    height={24}
                  />
                </div>
                {/* <p>{props.successData.meetingLink}</p> */}
                <a
                  href={props.successData.meetingLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className={`${styles.apptmntDtls_mtngLink}`}>
                    <p>https://twinsdigital.zoom.us/</p>
                  </div>
                </a>
              </div>
            ) : (
              <div className="d-flex align-items-center pt-3">
                <span>
                  <StaticImage
                    src="../../../images/doctor/mail.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                  />
                </span>
                <p>
                  The link to your upcoming video consultation will be sent to
                  your registered email.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Add to calendar section */}
      {props.successData.meetingLink ? (
        <div>
          {!props.addToC && props.addToC !== "1" ? (
            <div className={styles.apptmntDtls_calendarCta}>
              <button
                className={styles.apptmntDtls_calendarCta_btn}
                onClick={addToCalender}
              >
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.apptmntDtls_calendarCta_btnCntnt}`}
                >
                  {/* image */}
                  <div className={styles.apptmntDtls_calendarCta_img}>
                    <StaticImage
                      src="../../../images/doctor/googlecalendar.png"
                      alt=""
                      className="img-fluid"
                      placeholder="blurred"
                      quality={90}
                      width={15}
                      height={15}
                    />
                  </div>
                  {/* text */}
                  <p>Add to calendar</p>
                  {/* arrow icon */}
                  <span
                    className={`mediumIcon icon-arrow-forward`}
                    aria-hidden="true"
                  ></span>
                </div>
              </button>
            </div>
          ) : (
            <div className={styles.addToCon}>
              <div className={styles.CalCo1}>
                <Image
                  secure="true"
                  cloudName={config.CLOUDINARY_NAME}
                  publicId="Webinar/Vector_6_gktnq3.svg"
                  dpr="auto"
                  responsive
                  width="auto"
                  crop="scale"
                  responsiveUseBreakpoints="true"
                  fetchFormat="webp"
                  quality="auto"
                  className={styles.calImg1}
                >
                  <Placeholder type="blur"></Placeholder>
                </Image>
                <p className={styles.AddCanTxt1}>
                  Added to your google calender
                </p>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AppointmentDetails;
