import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// styles
import * as styles from "./Header.module.css";

const Header = props => {
  return (
    <div>
      {props.appHeader === "mobile" ? null : (
        <div className={styles.headerSec}>
          <div className={styles.header_imgSec}>
            <Link to="/">
              <StaticImage
                src="../../../images/twin-logo/twinLogo2_2024.png"
                alt=""
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
